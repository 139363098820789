import React from 'react'
import { Link, graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from '../components/Layout'

const PostIndex = ({
	data,
	pageContext: { nextPagePath, previousPagePath },
}) => {
	const posts = data.allWpPost.nodes

	if (!posts.length) {
		return (
			<Layout isHomePage>
				<p>
					No blog posts found. Add posts to your WordPress site and they'll
					appear here!
				</p>
			</Layout>
		)
	}

	return (
		<Layout isHomePage>
			<ol style={{ listStyle: `none` }}>
				{posts.map(post => {
					const title = post.title
					const featuredImage = {
						fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
						alt: post.featuredImage?.node?.alt || ``,
					}

					return (
						<li key={post.uri}>
							<article
								className="post-list-item"
								itemScope
								itemType="http://schema.org/Article"
							>
								<header>
									<h2>
										<Link to={post.uri} itemProp="url">
											<span itemProp="headline">{parse(title)}</span>
										</Link>
									</h2>
								</header>
							</article>
						</li>
					)
				})}
			</ol>

			{previousPagePath && (
				<>
					<Link to={previousPagePath}>Previous page</Link>
					<br />
				</>
			)}
			{nextPagePath && <Link to={nextPagePath}>Next page</Link>}
		</Layout>
	)
}

export default PostIndex

export const postQuery = graphql`
	query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
		allWpPost(
			sort: { fields: [date], order: DESC }
			limit: $postsPerPage
			skip: $offset
		) {
			nodes {
				uri
				date(formatString: "MMMM DD, YYYY")
				title
			}
		}
	}
`
